import { Ecolors, urlApp } from 'constant';
import { Log } from './../utils/utils';
import { apiMain } from './apis/apiMain';

// export const configEkyc = {
//   domain: `${urlApp.APIURL}api`,
//   configEndpoint: {
//     front: '/ekyc/v1.2/id/verify/front',
//     back: '/ekyc/v1.2/id/verify/back',
//     selfie: '/ekyc/v1.2/selfie/verify',
//     complete: '/ekyc/v1.2/complete',
//     nfcqrverify: '/ekyc/v2.0/nfcqr/verify',
//     nfcrar: '/ekyc/v1.2/nfcqr/upload',
//     ocr: '/ekyc/v1.2/id/ocr',
//     nfcbshield: '/ekyc/v1.2/nfcqr/upload',
//     createrequest: '/ekyc/v1.2/request/create',
//     accesstoken: '/auth/v1/oauth/accessToken',
//   },
//   stepVerification: ['FRONT', 'BACK', 'SELFIE', 'EDITOCR'],
//   titleColor: Ecolors.textColor, //80%
//   subTitleColor: Ecolors.grayColor, //60%
//   closeColor: Ecolors.textColor, //80%
//   buttonCaptureColor: Ecolors.mainColor,
//   titleButtonCaptureColor: '#ffffff',
//   backgroundColor: '#ffffff',
//   requestId: '',
//   // accessToken: '',
//   clientId: 'ea8df9ebff8d38479058d7f1d235e097',
//   clientSecret: '+xzqA0O4GScV2dSiaB2cDiYVDY7hE0pG6rqN0TTNbU4=',
//   configHeader: '',
// };

// D export
export const configEkyc = {
  domain: `${urlApp.APIURL}api`,
  configEndpoint: {
    front: '/ekyc/v1.2/id/verify/front',
    back: '/ekyc/v1.2/id/verify/back',
    selfie: '/ekyc/v1.2/selfie/verify',
    complete: '/ekyc/v1.2/complete',
    nfcqrverify: '/ekyc/v2.0/nfcqr/verify',
    nfcrar: '/ekyc/v1.2/nfcqr/upload',
    ocr: '/ekyc/v1.2/id/ocr',
    nfcbshield: '/ekyc/v1.2/nfcqr/upload',
    createrequest: '/ekyc/v1.2/request/create',
    accesstoken: '',
  },
  stepVerification: ['FRONT', 'BACK', 'SELFIE', 'EDITOCR'],
  titleColor: Ecolors.textColor, //80%
  subTitleColor: Ecolors.grayColor, //60%
  closeColor: Ecolors.textColor, //80%
  buttonCaptureColor: Ecolors.mainColor,
  titleButtonCaptureColor: '#ffffff',
  backgroundColor: '#ffffff',
  requestId: '',
  clientId: 'ea8df9ebff8d38479058d7f1d235e097',
  clientSecret: '+xzqA0O4GScV2dSiaB2cDiYVDY7hE0pG6rqN0TTNbU4=',
  accessToken: '',
  configHeader: '',
};

// export const convertDaTaNewCC = async cardInfo => {
//   const {
//     person,
//     backCardImage,
//     frontCardImage,
//     ekycResult: {
//       kyc_result: {front, decision, back},
//     },
//   } = cardInfo;
//   const listCountry = await apiMain.getCountry();
//   const country = listCountry.data.find((a: any) => a?.id == '234');
//   const listProvince = await apiMain.getProvince({
//     countryId: 234,
//   });
//   const province = listProvince.data.find((a: any) =>
//     a.administrativeCode == back?.id_address_province?.code
//       ? back?.id_address_province?.code
//       : 0,
//   );
//   const listDistrict = await apiMain.getDistrict({
//     provinceId: province?.id || 0,
//   });
//   const district = listDistrict.data.find((a: any) =>
//     a.administrativeCode == back?.id_address_district?.code
//       ? back?.id_address_district?.code
//       : 0,
//   );
//   const listWard = await apiMain.getWard({
//     districtId: district?.id || 0,
//   });
//   const ward = listWard.data.find((a: any) =>
//     a.administrativeCode == back?.id_address_ward?.code
//       ? back?.id_address_ward?.code
//       : 0,
//   );

//   const userProfile = {
//     gender: person.gender == 'MALE' ? 1 : 0,
//     dob: convertStringTime(person.dob || ''),
//     nationalityId: 234,
//     idTypeId: 1,
//     idNo: person?.idNumber,
//     dateOfIssue: convertStringTime(person.doi),
//     placeOfIssue: person.givenPlace,
//   };

//   const userAddress = {
//     permanentAddress: back?.id_address?.value || '',
//     countryId: 234,
//     provinceId: province?.id || 0,
//     districtId: district?.id || 0,
//     wardId: ward?.id || 0,
//     //
//     mailingAddress: back?.id_address?.value || '',
//     mailingCountryId: 234,
//     mailingProvinceId: province?.id || 0,
//     mailingDistrictId: district?.id || 0,
//     mailingWardId: ward?.id || 0,
//     country,
//     province,
//     district,
//     ward,
//   };

//   return {
//     userProfile,
//     userAddress,
//     isKYC: decision?.code == 1,
//     name: person.fullname,
//     person,
//     backCardImage,
//     frontCardImage,
//     cardInfo,
//   };
// };

export const convertDaTaCCCD = async result => {
  const {
    idInfo: {
      id_address_province,
      id_address_district,
      id_address_ward,
      id_address,
      // dob,
      // gender,
      // id_number,
      name,
    },
    rawImage,
    ekycResult: {
      kyc_result: {
        back: {given_date, given_place},
        complete: {dob, gender, full_name, id_number},
      },
    },
    decision,
  } = result;

  const listCountry = await apiMain.getCountry();
  const country = listCountry.data.find((a: any) => a?.id == '234');
  const listProvince = await apiMain.getProvince({
    countryId: 234,
  });
  const province = listProvince.data.find((a: any) =>
    a.administrativeCode == id_address_province?.code
      ? id_address_province?.code
      : 0,
  );
  const listDistrict = await apiMain.getDistrict({
    provinceId: province?.id || 0,
  });
  const district = listDistrict.data.find((a: any) =>
    a.administrativeCode == id_address_district?.code
      ? id_address_district?.code
      : 0,
  );
  const listWard = await apiMain.getWard({
    districtId: district?.id || 0,
  });
  const ward = listWard.data.find((a: any) =>
    a.administrativeCode == id_address_ward?.code ? id_address_ward?.code : 0,
  );

  const userAddress = {
    permanentAddress: id_address?.value || '',
    countryId: 234,
    provinceId: province?.id || 0,
    districtId: district?.id || 0,
    wardId: ward?.id || 0,
    //
    mailingAddress: id_address?.value,
    mailingCountryId: 234,
    mailingProvinceId: province?.id || 0,
    mailingDistrictId: district?.id || 0,
    mailingWardId: ward?.id || 0,
    //
    country,
    province,
    district,
    ward,
    //
  };
  const userProfile = {
    nationalityId: 234,
    idTypeId: 1,
    dateOfIssue: given_date?.value || '',
    placeOfIssue: given_place?.value || '',
    gender: gender,
    dob:  dob,
    idNo: id_number,
    name: full_name, // name?.value || '',
  };
  const obj = {
    userAddress,
    userProfile,
    isKYC: decision?.code == 1,
    rawImage,
  };
  Log('obj', obj);
  return obj;
};

// var stringJsonColor = `{\"main_color\":\"${Ecolors.mainColor}\",\"second_color\":\"${Ecolors.bgtime}\",\"text_color\":\"${Ecolors.textColor}\",\"border_input_color\":\"${Ecolors.bordercolor}\",\"background_color\":\"${Ecolors.whiteColor}\",\"close_color\":\"${Ecolors.textColor}\"}`;
// var PublicFaceScanEncryptionKey =
//   '-----BEGIN PUBLIC KEY-----\n' +
//   'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAtYdqkDpAE9umyJDfapTa\n' +
//   'z+nIs3f8Qzc15v268pXGO1lNdRa5Qi1QtxNkfh9D043/8ySNbLWt6zpFEFi98geU\n' +
//   'kMLrqEA9UeeRHBvxjfBl+8DMsP1QtOsgbukyNHZIDRNDcn0nnSvxdjzvTsnYrR6N\n' +
//   'YHJ2jyrObKR9bGBPInjfNGjz0L2jFcWYNmO//5wA2Wza4uqOZS28sjMHmTcmiWfX\n' +
//   'tW9oUQTTKcFA9uZu2A+3hvwjWsAxg4cP1B7BLRZQDhvKfgs2IQKfcbwTDqKZunK/\n' +
//   'ooH/fMuPZLFnevxlyFAsC+RJ1Tb355gaAmkFDiVKZJzOzY8gWjVpA6fxXrJe1V9o\n' +
//   '/wIDAQAB\n' +
//   '-----END PUBLIC KEY-----';
// var stringHeaderConfig = `{\"Origin\":\"${urlApp.DomainName}\"}`;

// var configInfo = {
//   domain: `${urlApp.APIURL}api`,
//   domainPath: '/ekyc/v1.2',
//   authDomain: 'https://api.trueid.ai',
//   authDomainPath: '/v1/oauth',
//   appId: 'ea8df9ebff8d38479058d7f1d235e097',
//   appSecret: '+xzqA0O4GScV2dSiaB2cDiYVDY7hE0pG6rqN0TTNbU4=',
//   zoomLicenseKey: 'dSERDnSNV8KzqajJJMEfA353JgNV27jb',
//   zoomServerBaseURL: 'https://liveness-test.trueid.ai',
//   zoomPublicKey: PublicFaceScanEncryptionKey,
//   zoomAuthURL: 'https://onboard-liveness.trueid.ai/liveness/key',
//   language: 'vi',
//   themeColor: stringJsonColor,
//   headerConfig: stringHeaderConfig,
// };

// var configInfoLive = {
//   domain: `${urlApp.APIURL}api`,
//   domainPath: '/ekyc/v1.2',
//   authDomain: 'https://api.trueid.ai',
//   authDomainPath: '/v1/oauth',
//   appId: 'd55d96e8506b75480a68af3e6aedcfc3',
//   appSecret: 'gtK7Z+67cHqAlBRUp/oXzxhDjNCdyLh6lt//A7Ihp4o=',
//   zoomLicenseKey: 'dSERDnSNV8KzqajJJMEfA353JgNV27jb',
//   zoomServerBaseURL: 'https://liveness-test.trueid.ai',
//   zoomPublicKey: PublicFaceScanEncryptionKey,
//   zoomAuthURL: 'https://onboard-liveness.trueid.ai/liveness/key',
//   language: 'vi',
//   themeColor: stringJsonColor,
//   headerConfig: stringHeaderConfig,
// };

// export function startScan(
//   startLoading?: () => void,
//   endLoading?: () => void,
//   config?: {
//     language?: string;
//     accessToken?: string;
//   },
// ) {
//   return;
//   return new Promise(
//     async (resolve: (e: any) => void, reject: (e: any) => void) => {
//       try {
//         const token = await getStoreToken();
//         await RNTrueId.configure({
//           ...configInfo,
//           accessToken: `${token}`,
//           ...config,
//         });
//         return RNTrueId.start(async (cardInfo: any) => {
//           startLoading && startLoading();
//           const {
//             person,
//             backCardImage,
//             frontCardImage,
//             result: {
//               kyc_result: {front, decision, rule_table},
//             },
//           } = cardInfo;

//           var isKYC = decision.code == 1;

//           if (decision.code != 1) {
//             var ArrayCheckRule = [];
//             rule_table.map((item: any, index) => {
//               ArrayCheckRule = ArrayCheckRule.concat(
//                 item.rules.filter(item => item.check_result == 2),
//               );
//               return item.rules;
//             });
//             if (ArrayCheckRule.length) {
//               if (ArrayCheckRule.length > 2) {
//                 isKYC = false;
//               } else if (
//                 ArrayCheckRule.find(
//                   (item: any) =>
//                     item.rule_code != 'DX-005' && item.rule_code != 'DX-006',
//                 )
//               ) {
//                 isKYC = false;
//               } else {
//                 isKYC = true;
//               }
//             }
//           }

//           const listCountry = await apiMain.getCountry();
//           const country = listCountry.data.find((a: any) => a.id == '234');
//           const listProvince = await apiMain.getProvince({
//             countryId: 234,
//           });
//           const province = listProvince.data.find(
//             (a: any) => a.administrativeCode == front.id_address_province?.code,
//           );
//           const listDistrict = await apiMain.getDistrict({
//             provinceId: province?.id || 0,
//           });

//           const district = listDistrict.data.find(
//             (a: any) => a.administrativeCode == front.id_address_district?.code,
//           );
//           const listWard = await apiMain.getWard({
//             districtId: district?.id || 0,
//           });
//           const ward = listWard.data.find(
//             (a: any) => a.administrativeCode == front.id_address_ward?.code,
//           );
//           const userProfile = {
//             gender: person.gender == 'MALE' ? 1 : 0,
//             dob: convertStringTime(person.dob || ''),
//             nationalityId: 234,
//             idTypeId: 1,
//             idNo: person.idNumber,
//             dateOfIssue: convertStringTime(person.doi),
//             placeOfIssue: person.givenPlace,
//           };
//           const userAddress = {
//             permanentAddress: front.id_address.value,
//             countryId: 234,
//             provinceId: province?.id,
//             districtId: district?.id,
//             wardId: ward?.id || 0,
//             //
//             // mailingAddress: getAddressRejectWard(ward, front.id_address.value),
//             mailingAddress: front.id_address.value,
//             mailingCountryId: 234,
//             mailingProvinceId: province?.id,
//             mailingDistrictId: district?.id,
//             mailingWardId: ward?.id || 0,
//             country,
//             province,
//             district,
//             ward,
//           };
//           resolve({
//             userProfile,
//             userAddress,
//             isKYC: isKYC, // decision?.code == 1,
//             name: person.fullname,
//             person,
//             backCardImage,
//             frontCardImage,
//             cardInfo,
//           });
//           return {
//             userProfile,
//             userAddress,
//             isKYC: isKYC, // decision?.code == 1,
//             name: person.fullname,
//             person,
//             backCardImage,
//             frontCardImage,
//             cardInfo,
//           };
//         });
//       } catch (error) {
//         endLoading && endLoading();
//         reject(null);
//         throw null;
//       } finally {
//         endLoading && endLoading();
//       }
//     },
//   );
// }
